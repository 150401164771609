(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name members.controller:MembersCtrl
   *
   * @description
   *
   */
  angular
    .module('members')
    .controller('MembersCtrl', MembersCtrl);

  function MembersCtrl($location, $localStorage, RCRUsers) {
    var vm = this;
    vm.ctrlName = 'MembersCtrl';

    if(!RCRUsers.isAuthenticated()){$location.path('/');}
	
	if ($localStorage.loggedInUser.userType == 1) {
		$location.path('/home');
	}
	
	RCRUsers.find({
			"filter": {
				"where": {
					"userType":1,
					"email":{"nlike":"%@limeworx.uk"}
				}
			}
		}).$promise.then(function(results){
			_.forEach(results,function(result, key){
				
			});
			vm.memberslist = results;
		});
  }
}());
